var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home"},[_c('PartnerNavigation',{attrs:{"companyName":_vm.companyName,"picName":_vm.picName}}),_c('div',{staticClass:"homepage"},[_c('div',{staticClass:"homepage__profile"},[_vm._m(0),_c('div',{staticClass:"homepage__profile_content"},[_vm._m(1),_c('div',{staticClass:"homepage__profile_content_right-column"},[_c('div',{staticClass:"homepage__profile_content_right-column_name"},[_c('p',[_vm._v(_vm._s(_vm.picName))])]),_c('div',{staticClass:"homepage__profile_content_right-column_detail"},[_vm._m(2),_c('div',{staticClass:"homepage__profile_content_right-column_detail_content"},[_c('p',[_vm._v(_vm._s(_vm.companyName))]),_c('p',[_vm._v(_vm._s(_vm.phoneNumber))]),_c('p',[_vm._v(_vm._s(_vm.picEmail))])])])])])]),(_vm.isShow === false)?_c('div',{staticClass:"homepage__agreement-empty"},[_c('img',{attrs:{"src":require("@/assets/images/companies-register.png")}}),_c('p',{staticClass:"homepage__agreement-empty_welcome"},[_vm._v("Selamat datang, "+_vm._s(_vm.picName))]),_c('p',{staticClass:"homepage__agreement-empty_detail"},[_vm._v("Seluruh perjanjian yang dibuat akan ditampilkan di halaman ini. Harap menunggu untuk informasi selanjutnya.")])]):_c('div',{staticClass:"homepage__agreement-list"},[_vm._m(3),_c('div',{staticClass:"homepage__agreement-list_table"},[_c('table',[_vm._m(4),_c('tbody',_vm._l((_vm.filteredAgreementList),function(item,index){return _c('tr',{key:index},[_c('td',[_c('p',[_vm._v(_vm._s(item.pipelineName))])]),_c('td',[_c('p',[_vm._v(_vm._s(item.picName))])]),_c('td',[_c('p',[_vm._v(_vm._s(_vm.picEmail))])]),_c('td',[_c('p',[_vm._v(_vm._s(item.assignTo))])]),_vm._m(5,true),_c('td',[_c('p',{staticClass:"document"},[_vm._v(_vm._s(item.docName))])]),_c('td',[_c('p',[_vm._v(_vm._s(item.docType))])]),_c('td',[_c('p',[_vm._v(_vm._s(item.docLink))])])])}),0)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"homepage__profile_header"},[_c('p',[_vm._v("Profil Mitra")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"homepage__profile_content_left-column"},[_c('img',{attrs:{"src":require("@/assets/images/icons/user.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"homepage__profile_content_right-column_detail_title"},[_c('p',[_vm._v("Perusahaan")]),_c('p',[_vm._v("No Telepon")]),_c('p',[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"homepage__agreement-list_header"},[_c('p',[_vm._v("Daftar Agreement")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('tr',[_c('th',[_c('p',[_vm._v("Pipeline Name")])]),_c('th',[_c('p',[_vm._v("Company PIC")])]),_c('th',[_c('p',[_vm._v("Company Email")])]),_c('th',[_c('p',[_vm._v("G2Academy PIC")])]),_c('th',[_c('p',[_vm._v("G2Academy Email")])]),_c('th',[_c('p',[_vm._v("Document")])]),_c('th',[_c('p',[_vm._v("Document Type")])]),_c('th',[_c('p',[_vm._v("Docusign Link")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('p',[_vm._v("-")])])
}]

export { render, staticRenderFns }