import { mapActions } from 'vuex'
const PartnerNavigation = () => import(/* webpackChunkName: "login-form" */ '@/components/header/PartnerNavigation')

export default {
  name: 'partner',
  components: { PartnerNavigation },
  data: () => ({
    companyName: '',
    picName: '',
    phoneNumber: '',
    picEmail: '',
    isChangePasswordModalVisible: false,
    isShow: false,
    allAgreementList: [],
    filteredAgreementList: []
  }),
  mounted() {
    this.fetchData()
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  methods: {
    ...mapActions('partner', ['getPartnerDetail', 'getAllAgreement']),
    fetchData() {
      this.getPartnerDetail({ id: localStorage.getItem('mitraId') }).then((res) => {
        this.companyName = res.companyName
        this.picName = res.picName
        this.phoneNumber = res.phoneNumber
        this.picEmail = res.picEmail
      })
      this.getAgreement()
    },
    getAgreement() {
      this.getAllAgreement({}).then((res) => {
        if (res.length > 0) {
          this.isShow = true
          this.allAgreementList = res
          this.filterList()
        } else {
          this.isShow = false
        }
      })
    },
    filterList() {
      for (let i = 0; i < this.allAgreementList.length; i++) {
        if (this.allAgreementList[i].picName.toLowerCase() === this.picName.toLowerCase()) {
          this.filteredAgreementList.push(this.allAgreementList[i])
        }
      }
    },
    toggleChangePasswordModal() {
      this.isChangePasswordModalVisible = !this.isChangePasswordModalVisible
    }
  }
}
